<template>
  <div id="container">
    <div class="title">食用农产品电子合格证</div>
    <div class="certificate">
      <ul class="info">
        <li>企业名称：{{Info.EnterprisesName}}</li>
        <li>产品名称：{{Info.ProductName}}</li>
        <li>产品类别：{{Info.ProductTypeName}}</li>
        <li>数量（重量）：{{Info.ProNum}} {{Info.UnitName}}</li>
        <li>联系方式：{{Info.Telephone}}</li>
        <li>产地：{{Info.AreaLongName}}</li>
        <li>生产日期：{{Info.ProDate}}</li>
        <li>保质期：{{Info.Exp}}</li>
        <li>批次编号：{{Info.PrintNum}}</li>
      </ul>
      <div class="card"><img :src="weburl + Info.EnterpriseSignature"></div>
      <ul class="subInfo">
        <li class="sub-title">我承诺对产品质量安全以及合格证真实性负责：</li>
        <li>1.不使用禁限用农药兽药</li>
        <li>2.不使用非法添加物</li>
        <li>3.遵守农药安全间隔期、兽药休药期规定</li>
        <li>4.销售的食用农产品符合农药兽药残留食品安全国家标准</li>
      </ul>
      <!-- <div class="QRcode"><img src="../assets/image/image_05.jpg"></div> -->
    </div>
  </div>
</template>
<script>
import { GetQCRecordByID } from '@/api/index'
export default {
  data () {
    return {
      PrintNum: '',
      Info: {},
      weburl: this.$store.state.webapiurl //获取图片地址
    }
  },
  created() {
    this.PrintNum = this.$route.query.PrintNum
    this.Init()
  },
  methods: {
    Init() {
      //获取合格证详情
      GetQCRecordByID(this.PrintNum).then(res => {
        this.Info = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
body {
  font-family: 'SourceHanSansCN-Regular';
}
#container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url('../assets/image/image_01.png'), url('../assets/image/image_02.png');
  background-position: left bottom, left top;
  background-size: 100%, 100%;
  background-repeat: no-repeat, no-repeat;
  background-color: #f3f3f3;
}
.title {
  width: 100%;
  text-align: center;
  font-size: 2.625rem;
  background: #FFFFFF;
  overflow: hidden;
  padding: 2rem 1.5rem 2.5rem 1.5rem;  
}
.certificate {
  width: 94%;
  margin: 3% 3% 0;
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(143, 143, 143, 0.22);
  border-radius: 0.625rem;
  overflow: hidden;
  padding: 1.875rem;
  box-sizing: border-box;
  background-image: url('../assets/image/image_03.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 100% 1.75rem;
  position: relative;
  .info {
    width: 100%;
    li {
      font-size: 1.75rem;
      color: #1B1E2E;
      font-weight: 600;
      margin-bottom: 1.5rem;
    }
  }
  .card {
    width: 14.0625rem;
    height: 14.0625rem;
    position: absolute;
    top: 2.625rem;
    right: 3.25rem;
    img {
      width: 100%;
    }
  }
  .subInfo {
    width: 100%;
    margin-top: 1.75rem;
    li {
      font-size: 1.125rem;
      color: #1B1E2E;
      font-weight: 500;
      margin-bottom: 1.25rem;
    }
    .sub-title {
      font-size: 1.375rem;
      color: #EF3C3B;
    }
  }
  .QRcode {
    width: 9.25rem;
    height: 9.25rem;
    position: absolute;
    bottom: 10rem;
    right: 2.375rem;
    img {
      width: 100%;
    }
  }
}
</style>